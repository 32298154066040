<template>
  <div class="payment-mode-group">
    <v-card
      v-for="(option, index) in options"
      :key="`PAYMENT_MODE_${ index }`"
      :class="`${ index === layouts.data.checkIndex ? 'active' : '' }`"
      class="payment-mode-card pa-0"
      @click="onClickPaymentMode(index)"
      flat
    >
      <v-card-text class="payment-mode-card--content">
        <v-row class="pa-0 ma-0">
          <v-col cols="2" class="pa-0">
            <div class="checkbox-input">
              <div class="checkbox-input--square">
                <v-icon class="checkbox-input--status" color="var(--theme_secondary)">{{ index === layouts.data.checkIndex ? 'check' : '' }}</v-icon>
              </div>
            </div>
          </v-col>
          <v-col cols="10" class="pa-0">
            <div class="checkbox-details">
              <div class="checkbox-details--label">
                {{ option.label }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'PaymentModeGroup',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: null
  },
  data () {
    return {
      layouts: {
        data: {
          checkIndex: null
        }
      }
    }
  },
  watch: {
    value (val) {
      this.layouts.data.checkIndex = val
    }
  },
  mounted () {
    this.layouts.data.checkIndex = this.value
    // 设置默认选中第一项
    this.onClickPaymentMode(0)
  },
  methods: {
    onClickPaymentMode (index) {
      this.layouts.data.checkIndex = index
      this.$emit('change', index)
      this.$emit('click', index)
    }
  }
}
</script>

<style lang="sass" scoped>
  .payment-mode-card
    width: 100%
    border-radius: 8px
    box-shadow: 0px 4px 8px 0px rgba(11,13,21,0.06) !important
    &--content
      padding: 0
      border-radius: 8px!important
    & .checkbox-input
      display: flex
      justify-content: center
      align-items: center
      height: 100%
      border-radius: 8px 0 0 8px
      background-color: #F4F5F9
      &--square
        display: flex
        justify-content: center
        align-items: center
        width: 26px
        height: 26px
        border-radius: 2px
        background-color: #ffffff
      &--status
        font-size: 16px
    & .checkbox-details
      padding: 16px
      border-radius: 0 8px 8px 0
      &--label
        font-size: 14px
        font-weight: 700
    &.active
      background-color: var(--theme_secondary)
      & .checkbox-input
        background-color: rgba(255, 255, 255, 0.2)
      & .checkbox-details--label
        color: #F5F5F5
        &--square
          background-color: #F5F5F5
        &--status
          color: var(--theme_primary)
      & .checkbox-details
        color: #F5F5F5
  .payment-mode-card + .payment-mode-card
    margin-top: 24px
</style>
<style>
.payment-mode-group .v-card--link:before{
  border-radius: 8px!important;
}
</style>
