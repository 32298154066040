<template>
  <v-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    class="date-picker"
    persistent>
    <div class="sheet-header px-3">
      <div class="sheet-left" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </div>
      <div class="sheet-middle f-s-18 f-w-900">{{ label }}</div>
      <div v-show="confirmControl" class="sheet-right" @click="onClickConfirm">
        <v-icon>mdi-check</v-icon>
      </div>
      <div v-show="!confirmControl" class="sheet-right">
      </div>
    </div>
    <van-datetime-picker
      v-model="pickerValue"
      :show-toolbar="false"
      :min-date="minDate"
      :max-date="maxDate"
      type="date"
    />
  </v-bottom-sheet>
</template>
<script>
import { Tool } from '@/assets/js/util'
import { DatetimePicker } from 'vant'
import 'vant/lib/datetime-picker/style/index'
import moment from 'moment'

const tool = new Tool()
export default {
  name: 'croc-date-picker',
  components: {
    [DatetimePicker.name]: DatetimePicker
  },
  props: {
    dateValue: null,
    label: {
      type: String,
      default: ''
    },
    allowed: {
      type: String,
      default: ''
    },
    minimumDate: {
      type: String,
      default: ''
    },
    maximumDate: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pickerValue: '',
      pattern: '',
      minDate: new Date(),
      maxDate: new Date(2030, 1, 1)
    }
  },
  watch: {
    dateValue () {
      this.pickerValue = new Date(this.dateValue) // vant 的date picker只接收new Date
    },
    allowed () {
      this.initDateValue()
    },
    minimumDate (val) {
      this.minDate = new Date(val)
    },
    maximumDate (val) {
      this.maxDate = new Date(val)
    },
    'store.getters.getDateTimePatterns': {
      handler: function (val) {
        if (tool.isNotEmpty(val)) {
          this.pattern = val.date
        }
      }
    }
  },
  computed: {
    confirmControl () {
      const val = this.pickerValue
      let result = true
      if (this.allowed) {
        if (this.allowed.indexOf('no-weekend') !== -1) {
          result = result && !tool.isWeekend(val)
        }

        if (this.allowed.indexOf('no-holiday') !== -1) {
          const value = moment(val).format('yyyy-MM-DD')
          result = result && !tool.isHoliday(value)
        }
      }
      return result
    }
  },
  mounted () {
    this.pattern = this.$store.getters.getDateTimePatterns.date || 'MM/DD/YYYY'
    this.initDateValue()
    this.pickerValue = new Date(this.dateValue)
  },
  methods: {
    initDateValue () {
      if (this.allowed.indexOf('start-now') !== -1) {
        const currentDate = new Date()
        currentDate.setDate(currentDate.getDate() + 1)
        this.minDate = currentDate // 可选日期最小为下一天
      }
    },
    onClickConfirm () {
      if (tool.isNotEmpty(this.pattern)) {
        const pattern = this.pattern.toUpperCase()
        const date = new Date(this.pickerValue)
        const isoDateString = date.toISOString() // 所有浏览器都支持ISO,避免苹果手机日期无法正常显示
        this.$emit('change', moment(isoDateString).format(pattern))
      }
    }
  }
}
</script>

<style lang="sass">
.v-overlay__scrim
  background-color: rgba(4, 31, 56, 0.3) !important

.v-dialog
  border-radius: 18px 18px 0 0 !important
</style>

<style lang="sass" scoped>
.sheet-header
  display: flex
  justify-content: space-between
  align-items: center
  background-color: #ffffff
  height: 44px
  border-radius: 18px 18px 0 0

  .sheet-left
    width: 15%

  .sheet-middle
    width: 70%
    text-align: center

  .sheet-right
    height: 24px
    width: 15%
    text-align: right
</style>
