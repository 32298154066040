<template>
  <v-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    persistent
  >
    <v-card class="card-content px-6" color="white">

      <div class="d-flex justify-space-between py-3">
        <v-btn icon disabled>
        </v-btn>
        <div class="text-center card-title f-s-18 f-w-900">
          {{ title }}
        </div>
        <v-btn icon>
          <v-icon size="20" color="#9CA0AB" @click="$emit('confirm')">mdi-check</v-icon>
        </v-btn>
      </div>

      <v-card flat class="payment-fee pa-2" color="#ededee">
        <v-row no-gutters>
          <template v-for="(item, index) in paymentFeeSetting">
            <v-col cols="6" class="field-label py-1" :key="index">{{ item.name }}</v-col>
            <v-col cols="6" class="text-right py-1" :key="item.name">{{ assist.currency.format(item.current) }}</v-col>
          </template>

          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>

          <v-col cols="6" class="py-1 f-s-16 f-w-900">Total</v-col>
          <v-col cols="6" class="text-right py-1 f-s-16 f-w-900 primary-text">{{
              assist.currency.format(this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total').current)
            }}
          </v-col>
        </v-row>
      </v-card>

      <v-card-text class="px-0 pt-4 pb-2">
        <div class="records-show-box">
          <vue-perfect-scrollbar class="scroll">
            <div
              v-for="(item, index) in paymentDetails"
              :key="index"
              class="records-item"
            >
              <div class="records-item-time pull-left">
                <div>
                  {{ item.transactionDate }}
                </div>
                <div v-if="index !== (paymentDetails.length - 1)" class="records-item-line"></div>
              </div>
              <div class="records-item-content pull-left"
                   :style="hasAction && item.transactionStatus !== assist.dataType.TransactionStatusEnum.NONE.value ? 'background-color: #c4ebc4' : ''">
                <div class="height-24 mt-3 mx-4 d-flex">
                  <div class="records-item-amount">
                    {{ assist.currency.format(item.amount) }}
                  </div>
                  <v-spacer></v-spacer>
                  <div class="records-item-text-name">
                      {{ assist.tool.getEnumTextByValue(assist.dataType.TransactionTypeEnum, item.transactionType).toUpperCase() }}
                  </div>
                </div>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </div>
      </v-card-text>
    </v-card>
  </v-bottom-sheet>
</template>

<script>
import { Tool, DataType, Currency } from '@/assets/js/util'

const tool = new Tool()
export default {
  name: 'preview-payment-schedule',
  props: {
    height: {
      type: [String, Number],
      default: 150
    },
    paymentDetails: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    hasAction: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      assist: {
        tool: tool,
        dataType: DataType,
        currency: new Currency()
      },
      layouts: {
        settings: {
          feeSetting: [
            {
              name: 'Principal',
              key: 'principal',
              value: 700
            },
            {
              name: 'Finance Fee',
              key: 'financeFee',
              value: 1000
            },
            {
              name: 'NSF Fee',
              key: 'nsfFee',
              value: 40
            },
            {
              name: 'Unpaid Fee',
              key: 'unpaidFee',
              value: 243
            },
            {
              name: 'Late Fee',
              key: 'lateFee',
              value: 243
            },
            {
              name: 'Bank Fee',
              key: 'bankFee',
              value: 175
            },
            {
              name: 'Refuse Fee',
              key: 'refuseFee',
              value: 55
            }
          ]
        }
      }
    }
  },
  computed: {
    paymentFeeSetting () {
      const init = [...this.$store.getters.getTransactionFeeList]
      const result = []
      init.map((item) => {
        if (item.fieldKey !== 'total') {
          result.push(item)
        }
      })
      return result
    }
  },
  mounted () {
  },
  watch: {},
  methods: {}
}
</script>

<style lang="sass" scoped>
.field-label
  color: #9CA0AB

.primary-text
  color: var(--theme_primary)

.card-content
  min-height: 260px
  border-radius: 18px 18px 0 0

.card-title
  line-height: 40px

.payment-fee
  border-radius: 10px

  &.v-divider
    background: red

.records-show-box
  width: 100%
  height: 400px

.scroll
  height: 100%
  width: 103%

.records-item + .records-item
  margin-top: 16px

.records-item
  height: 50px
  width: calc(100% - 10px)

.records-item-time
  width: 80px
  height: 28px
  font-size: 12px
  margin-top: 18px
  font-weight: 300
  text-align: left
  color: #9ba1b7
  line-height: 14px

.records-item-line
  width: 1px
  height: 22px
  margin-top: 12px
  margin-left: 30px
  border: 1px solid #eef1f8

.records-item-content
  margin-left: 16px
  width: calc(100% - 100px)
  height: 50px
  border: 1px solid #eef1f8
  border-radius: 4px 12px 12px 12px
  position: relative

.records-item-amount
  height: 24px
  font-size: 14px
  font-weight: 400
  color: #000410
  line-height: 24px

.records-item-text-name
  height: 24px
  font-size: 12px
  font-weight: 400
  color: #000410
  line-height: 24px
</style>
