<template>
  <v-bottom-sheet
    v-bind="$attrs"
    v-on="$listeners"
    :persistent="persistent"
    scrollable
  >
    <v-card class="card-content">
      <div class="d-flex justify-space-between py-3 px-2">
        <v-btn icon small fab @click="$emit('close')">
          <v-icon size="20" color="#9CA0AB">mdi-close</v-icon>
        </v-btn>
        <div class="text-center card-title f-s-18 f-w-900">
          {{ title }}
        </div>
        <v-btn icon small fab @click="$emit('confirm')">
          <v-icon size="20" color="#9CA0AB">mdi-check</v-icon>
        </v-btn>
      </div>

      <slot></slot>
    </v-card>
  </v-bottom-sheet>
</template>
<script>
export default {
  name: 'croc-bottom-sheet',
  props: {
    title: {
      type: String,
      default: ''
    },
    persistent: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="sass" scoped>
.card-content
  border-radius: 18px 18px 0 0
  padding-bottom: 8px
  background: white !important
/*min-height: 260px*/
.card-title
  line-height: 40px
</style>
<style lang="sass">
.van-picker
  border-radius: 12px 12px 0 0
  .van-picker-column__wrapper
    padding: 0
</style>
