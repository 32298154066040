<template>
  <div>
    <div class="px-6 mt-2">
      <payment-mode-group
        :options="layouts.settings.modeOptions"
        @change="modeChanged"
      ></payment-mode-group>

      <div class="mt-7">
        <v-card flat class="payment-options mt-7" width="100%">
          <div v-for="(option, index) in paymentOptions" :key="index">
            <div class="d-flex justify-space-between pa-2" @click="handlePaymentOptions(option.text, option.value)">
              <span>{{ option.text }}</span>
              <span class="payment-options-value">{{
                  option.text.includes('Amount') ? assist.currency.format(option.value) : option.value
                }}</span>
            </div>
            <v-divider v-if="index < layouts.settings.paymentOptions.length - 1" class="px-3"></v-divider>
          </div>
        </v-card>

        <div class="preview-panel mt-5">
          <a
            @click="previewPaymentPlan"
          >Preview Payment Plan</a>
        </div>
      </div>
    </div>

    <div class="footer">
      <wap-btn theme="normal" :width="50" :height="50" @click="onClickBack">
        <v-icon>navigate_before</v-icon>
      </wap-btn>
      <v-spacer></v-spacer>
      <wap-btn width="80%" :height="50" @click="onClickContinue">
        Continue
      </wap-btn>
    </div>

    <croc-bottom-sheet
      v-model="layouts.dialog.paymentActionDialog.display"
      :title="layouts.settings.dialogTitle"
      @close="layouts.dialog.paymentActionDialog.display = false"
      @confirm="onClickDown"
      :persistent="true"
    >
      <div class="px-5">
        <div class="text-center text-secondary f-s-20 f-w-900 pb-4">{{
            layouts.dialog.paymentActionDialog.currencyControl
              ? assist.currency.format(layouts.dialog.paymentActionDialog.progressValue)
              : layouts.dialog.paymentActionDialog.progressValue
          }}
        </div>

        <vue-slider v-model="layouts.dialog.paymentActionDialog.progressValue"
                    :height="25"
                    :railStyle="{background: 'var(--theme_secondary_opacity_high)', boxShadow: '0px 4px 8px 0px rgba(75,75,75,0.25) inset'}"
                    :processStyle="{background: 'var(--theme_secondary)'}"
                    :min="layouts.dialog.paymentActionDialog.sliderMinValue"
                    :max="layouts.dialog.paymentActionDialog.sliderMaxValue"
                    tooltip="none"
                    :silent="true"
                    :dot-size="24"
                    :interval="layouts.dialog.paymentActionDialog.intervalValue"
        >
        </vue-slider>

        <div v-if="!layouts.dialog.paymentActionDialog.currencyControl" class="f-s-12 py-5">
          Your loan will be paid off over the next<span
          class="text-secondary"> {{ layouts.dialog.paymentActionDialog.progressValue }}</span> payments.
        </div>
        <div class="f-s-12 py-5" v-else>You will have priority in repaying part of the principal.</div>

      </div>
    </croc-bottom-sheet>

    <croc-date-picker
      v-model="layouts.dialog.datePickerDialog.display"
      :date-value="layouts.dialog.datePickerDialog.value"
      label="Next Payment Date"
      :minimum-date="layouts.dialog.datePickerDialog.nextBusinessDate"
      :maximum-date="layouts.dialog.datePickerDialog.lastPaymentDate"
      :allowed="layouts.dialog.datePickerDialog.allowedDate"
      @close="layouts.dialog.datePickerDialog.display = false"
      @change="handleDateChange"
    />

    <wap-bottom-sheet v-model="layouts.dialog.selectSheet.display"
                      :persistent="true"
                      title="Payment Frequency"
                      @cancel="layouts.dialog.selectSheet.display = false"
                      @confirm="confirmPickerValue"
    >
      <van-picker
        ref="picker"
        :columns="pickerDomain"
      />
    </wap-bottom-sheet>

    <preview-payment-schedule
      v-model="layouts.dialog.paymentPlanDisplay.display"
      title="Payment Plan"
      :payment-details="layouts.dialog.paymentPlanDisplay.paymentDetails"
      @confirm="layouts.dialog.paymentPlanDisplay.display = false"
    >
      <div slot="title">Payment Plan</div>
    </preview-payment-schedule>
  </div>
</template>

<script>
import PaymentModeGroup from '@/components/collection/PaymentModeGroup'
import PreviewPaymentSchedule from '@/components/collection/PreviewPaymentSchedule'
import WapBtn from '@/components/base/WapBtn'
import CrocDatePicker from '@/components/collection/CrocDatePicker'
import CrocBottomSheet from '@/components/collection/CrocBottomSheet'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import { Currency, DataType, Tool } from '@/assets/js/util'
import { PaymentApi, InfrastructureApi } from '@/api'
import WapBottomSheet from '@/components/base/WapBottomSheet'
import { Picker } from 'vant'

export default {
  name: 'ScheduleNewPayment',
  components: {
    WapBottomSheet,
    PaymentModeGroup,
    PreviewPaymentSchedule,
    WapBtn,
    CrocBottomSheet,
    VueSlider,
    CrocDatePicker,
    [Picker.name]: Picker
  },
  data: () => ({
    assist: {
      currency: new Currency(),
      tool: new Tool()
    },
    layouts: {
      data: {
        selectedOption: null,
        nextPaymentDate: '', // 绑定用户选择的时间
        paymentsCount: 2,
        paymentsAmount: 50,
        paymentFrequency: 'Weekly'
      },
      settings: {
        modeOptions: [
          { id: 1, label: 'Pay Off In N Payments' },
          { id: 2, label: 'Pay A Fixed Amount Each Time' },
          { id: 2, label: 'One Time Payment' }
        ],
        paymentOptions: [],
        dialogTitle: ''
      },
      dialog: {
        paymentActionDialog: {
          display: false,
          progressValue: 0,
          currencyControl: false,
          text: '',
          sliderMinValue: 0,
          sliderMaxValue: 10,
          intervalValue: 1
        },
        datePickerDialog: {
          display: false,
          allowedDate: '',
          value: '',
          nextBusinessDate: '', // 用于限定最小的可选日期
          lastPaymentDate: '' // 用于限定最大的可选日期
        },
        selectSheet: {
          display: false,
          value: ''
        },
        paymentPlanDisplay: {
          display: false,
          paymentDetails: []
        }
      }
    }
  }),
  computed: {
    paymentOptions () {
      return { ...this.layouts.settings.paymentOptions }
    },
    pickerDomain () {
      return this.assist.tool.getWapArrayByEnum(DataType.PaymentFrequencyEnum)
    }
  },
  watch: {
    'layouts.data.selectedOption': {
      handler (val) {
        switch (val) {
          case 0:
            this.layouts.settings.paymentOptions = [{
              text: 'Next Payment Date',
              key: 'nextPaymentDate',
              value: this.layouts.data.nextPaymentDate
            },
            {
              text: 'Payment Frequency',
              key: 'paymentFrequency',
              value: this.layouts.data.paymentFrequency
            },
            {
              text: 'Payments Count',
              key: 'paymentsCount',
              value: this.layouts.data.paymentsCount
            }]
            break
          case 1:
            this.layouts.settings.paymentOptions = [{
              text: 'Next Payment Date',
              key: 'nextPaymentDate',
              value: this.layouts.data.nextPaymentDate
            },
            {
              text: 'Payment Frequency',
              key: 'paymentFrequency',
              value: this.layouts.data.paymentFrequency
            },
            {
              text: 'Payments Amount',
              key: 'paymentsAmount',
              value: this.layouts.data.paymentsAmount
            }]
            break
          case 2:
            this.layouts.settings.paymentOptions = [{
              text: 'Next Payment Date',
              key: 'nextPaymentDate',
              value: this.layouts.data.nextPaymentDate
            }]
            break
          default:
            break
        }
      }
    },
    'layouts.data.nextPaymentDate': {
      handler (val) {
        this.layouts.settings.paymentOptions.find((item) => item.key === 'nextPaymentDate').value = val
      }
    },
    'layouts.data.paymentFrequency': {
      handler (val) {
        this.layouts.settings.paymentOptions.find((item) => item.key === 'paymentFrequency').value = val
      }
    },
    'layouts.data.paymentsCount': {
      handler (val) {
        this.layouts.settings.paymentOptions.find((item) => item.key === 'paymentsCount').value = val
      }
    },
    'layouts.data.paymentsAmount': {
      handler (val) {
        this.layouts.settings.paymentOptions.find((item) => item.key === 'paymentsAmount').value = val
      }
    },
    'layouts.data': {
      handler () {
        // layouts.data中的任何数据发生变化都会重新调接口，所以该对象下的数据定义需要约束，仅是影响分期计算的放在该对象下
        this.paymentActions(DataType.PAYMENT_ACTION.PREVIEW.value)
      },
      deep: true
    },
    'layouts.dialog.paymentActionDialog.progressValue': {
      handler () {
        this.changeDotPosition()
      }
    }
  },
  async mounted () {
    await this.getDateRange()
    // await this.paymentActions(DataType.PAYMENT_ACTION.PREVIEW.value)
  },
  methods: {
    changeDotPosition () {
      // 获取vue-slider组件内的dot元素，修改其最大值和最小值的偏移距离
      this.$nextTick(
        () => {
          const sliderDot = document.getElementsByClassName('vue-slider-dot')
          sliderDot[0].style.transform = 'translate(-100%, -50%)'

          const value = this.layouts.dialog.paymentActionDialog.progressValue
          const min = this.layouts.dialog.paymentActionDialog.sliderMinValue
          if (value === min) {
            sliderDot[0].style.transform = 'translate(0, -50%)'
          }
        }
      )
    },
    async getDateRange () {
      await InfrastructureApi.getNextBusinessDay((res) => {
        this.layouts.data.nextPaymentDate = res
        this.layouts.dialog.datePickerDialog.nextBusinessDate = res
      }, () => {})

      await InfrastructureApi.getFutureBusinessDay((res) => {
        this.layouts.dialog.datePickerDialog.lastPaymentDate = res
      }, () => {})
    },
    paymentActions (action) {
      if (this.assist.tool.isEmpty(this.layouts.data.nextPaymentDate)) return

      const payOffType = this.layouts.data.selectedOption === 0
        ? DataType.PayOffEnum.PAY_OFF_IN_N.value
        : this.layouts.data.selectedOption === 1
          ? DataType.PayOffEnum.PAY_FIXED_AMOUNT.value
          : DataType.PayOffEnum.ONE_TIME_PAYMENT.value

      const request = {
        loanNo: this.$store.getters.getCollectionLoanNo,
        changeType: payOffType,
        action: action,
        couponCode: this.$store.getters.getCouponCode,
        effectiveDate: this.layouts.data.nextPaymentDate,
        debitCount: this.layouts.data.selectedOption === 0 ? this.layouts.data.paymentsCount : null,
        regularAmount: this.layouts.data.selectedOption === 1 ? this.layouts.data.paymentsAmount : null,
        currentInterest: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'financeFee')?.current || 0,
        currentExtraInterest: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'unpaidFee')?.current || 0,
        currentPrincipal: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'principal')?.current || 0,
        currentLateFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'lateFee')?.current || 0,
        currentNsfFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'nsfFee')?.current || 0,
        currentBankFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'bankFee')?.current || 0,
        currentRefuseFee: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'refuseFee')?.current || 0,
        currentTotal: this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total')?.current || 0,
        payrollFrequency: this.layouts.data.selectedOption === 2 ? null : DataType.PaymentFrequencyEnum.find(i => i.text === this.layouts.data.paymentFrequency).value
      }
      // 切换时置空，保证installment的数据对应
      this.layouts.dialog.paymentPlanDisplay.paymentDetails = []

      switch (this.layouts.data.selectedOption) {
        case 0:
          PaymentApi.payOffInN(request, (res) => {
            this.layouts.dialog.paymentPlanDisplay.paymentDetails = [...res]
            if (action === DataType.PAYMENT_ACTION.SAVE.value) this.$router.push('/notification/103')
          }, (err) => this.assist.tool.setPopupInformation(err.message))
          break
        case 1:
          PaymentApi.payFixedAmount(request, (res) => {
            this.layouts.dialog.paymentPlanDisplay.paymentDetails = [...res]
            if (action === DataType.PAYMENT_ACTION.SAVE.value) this.$router.push('/notification/103')
          }, (err) => this.assist.tool.setPopupInformation(err.message))
          break
        case 2:
          PaymentApi.oneTimePayment(request, (res) => {
            // one time payment只会有一期还款，返回的参数仅有一个对象
            this.layouts.dialog.paymentPlanDisplay.paymentDetails = [res]
            if (action === DataType.PAYMENT_ACTION.SAVE.value) this.$router.push('/notification/103')
          }, (err) => this.assist.tool.setPopupInformation(err.message))
          break
        default:
          break
      }
    },
    modeChanged (index) {
      this.layouts.data.selectedOption = index
      // 设置进度条bottom sheet的title
      this.layouts.settings.dialogTitle = index === 1 ? 'Pay A Fixed Amount' : this.layouts.settings.modeOptions[index].label
    },
    handlePaymentOptions (text, value) {
      const max = this.$store.getters.getTransactionFeeList.find(i => i.fieldKey === 'total')?.current
      switch (text) {
        case 'Payments Amount':
          this.layouts.dialog.paymentActionDialog.sliderMinValue = 50
          this.layouts.dialog.paymentActionDialog.intervalValue = 25
          this.layouts.dialog.paymentActionDialog.sliderMaxValue = Math.floor(max / 25) * 25 // payment amount 的最大值限制为小于total amount的最大25倍数
          this.layouts.dialog.paymentActionDialog.display = true
          this.layouts.dialog.paymentActionDialog.progressValue = value
          this.layouts.dialog.paymentActionDialog.currencyControl = text === 'Payments Amount'
          break
        case 'Payments Count':
          this.layouts.dialog.paymentActionDialog.sliderMinValue = 2
          this.layouts.dialog.paymentActionDialog.sliderMaxValue = 6 // 暂时定
          this.layouts.dialog.paymentActionDialog.intervalValue = 1
          this.layouts.dialog.paymentActionDialog.display = true
          this.layouts.dialog.paymentActionDialog.progressValue = value
          this.layouts.dialog.paymentActionDialog.currencyControl = text === 'Payments Amount'
          break
        case 'Next Payment Date':
          this.layouts.dialog.datePickerDialog.display = true
          this.layouts.dialog.datePickerDialog.value = value
          this.layouts.dialog.datePickerDialog.allowedDate = 'no-weekend no-holiday next-payment-date-within-two-weeks'
          break
        case 'Payment Frequency':
          this.layouts.dialog.selectSheet.display = true
          this.layouts.dialog.selectSheet.value = value
          break
        default:
          break
      }
    },
    previewPaymentPlan () {
      this.layouts.dialog.paymentPlanDisplay.display = true
    },
    handleDateChange (date) {
      this.layouts.dialog.datePickerDialog.display = false
      this.layouts.data.nextPaymentDate = date
    },
    onClickDown () {
      this.layouts.dialog.paymentActionDialog.display = false
      // 只有layouts.data.selectedOption为0，1才会有进度条
      if (this.layouts.data.selectedOption === 1) {
        this.layouts.data.paymentsAmount = this.layouts.dialog.paymentActionDialog.progressValue
      } else {
        this.layouts.data.paymentsCount = this.layouts.dialog.paymentActionDialog.progressValue
      }
    },
    confirmPickerValue () {
      const selectedData = this.$refs.picker.getValues()
      this.layouts.data.paymentFrequency = selectedData[0].text
      this.layouts.dialog.selectSheet.display = false
    },
    onClickBack () {
      this.$router.push('/coupon/overview')
    },
    onClickContinue () {
      this.paymentActions(DataType.PAYMENT_ACTION.SAVE.value)
    }
  }
}
</script>

<style lang="sass" scoped>
.payment-options
  color: #9CA0AB !important
  background-color: #eeeeee
  border-radius: 8px
  padding: 8px

  &-value
    color: #000410

  & .v-divider
    border-color: #FFFFFF

.preview-panel
  width: 100%

  & a
    color: var(--theme_primary)

.text-secondary
  color: var(--theme_secondary)
</style>
